import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../../CoustamComponents/Sidebar";
import { ApiConfig } from "../../../../Services/AuthAction/ApiConfig";
import { ProfileContext } from "../../../../Services/ProfileProvider";
import moment from "moment";

const ProfilePage = () => {

  const { userDetails } = useContext(ProfileContext);

  return (
    <div className="wrapper">
      <div className="d-flex align-items-stretch">
        <Sidebar />
        <div className="page-content">
          <div className="bg-dash-dark-2 py-4">
            <div className="container-fluid">
              <h2 className="h5 mb-0"> My Profile</h2>{" "}
            </div>
          </div>
          <div className="container-fluid py-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb py-2 px-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Profile
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  My Profile
                </li>
              </ol>
            </nav>
          </div>
          <section className="pt-0">
            <div className="container-fluid">
              <div className="row gy-4">
                <div className="col-xxl-7 col-xl-8 col-lg-8 m-auto">
                  <div className="card  h-100">
                    <div className="card-header">
                    </div>
                    <div className="card-body pt-0">
                      <form>
                        <div className="row mb-3" >
                          <div className="col-xxl-4 col-xl-4 col-lg-4">
                            <div className="avatar-preview">
                              <img
                                className="img-fluid"
                                src={userDetails?.profilePicture ? ApiConfig.baseUrl + userDetails.profilePicture : '/images/user_thumb.png'}
                                alt="user avatar"
                              />
                            </div>
                          </div>
                          <div className="col-xxl-8 col-xl-8 col-lg-8">
                            <div className="mb-3">
                              <label className="form-label mb-0" htmlFor="exampleInputName">
                                Name
                              </label>
                              <h6>{userDetails?.userName}</h6>
                            </div>
                            <div className="mb-0">
                              <label className="form-label mb-0" htmlFor="exampleInputName">
                                Email
                              </label>
                              <h6>{userDetails?.email}</h6>
                            </div>
                          </div>

                        </div>
                        <div className="react-bootstrap-table" >
                          <table className="table" >
                            <tbody>
                              <tr>
                                <td>Partner ID</td>
                                <td><b>{userDetails?.PartnershipId}</b></td>
                              </tr>
                              <tr>
                                <td>Status</td>
                                <td><b>{userDetails?.status}</b></td>
                              </tr>
                              <tr>
                                <td>Mobile No</td>
                                <td><b>{userDetails?.phoneNumber || '-----'}</b></td>
                              </tr>
                              <tr>
                                <td> Registration Date </td>
                                <td><b> {moment(userDetails.createdAt).format("DD/MM/YYYY")} </b></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <Link to="/editProfile" className="btn btn-primary w-100" > Edit Profile  </Link>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default ProfilePage
