import React, { useEffect, useState } from "react";
import Sidebar from "../../CoustamComponents/Sidebar";
import AuthService from "../../../Services/AuthService";
import LoaderHelper from "../../../Services/Loading/LoaderHelper";
import moment from "moment";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const ComissionReport = () => {
    const [commissionData, setCommissionData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10; // Number of items to display per page

    const getTotalComission = async () => {
        LoaderHelper.loaderStatus(true);
        try {
            const comissionPerDay = await AuthService.partner_monthly_payouts_list();
            if (comissionPerDay?.success) {
                setCommissionData(comissionPerDay?.data);
            }
        } finally {
            LoaderHelper.loaderStatus(false);
        }
    };

    const toFixed = (data) => parseFloat(data?.toFixed(6))

    const columns = [
        { name: 'Sr no.', selector: (row, index) => index + 1 + currentPage * itemsPerPage, wrap: true },
        { name: 'Date', sort: true, selector: row => moment(row?.createdAt).format('MMMM Do YYYY'), wrap: true },
        { name: 'Comission ($)', selector: row => toFixed(row?.per_month_credited_commission), wrap: true },
        { name: 'Total Partners Comission ($)', width: "250px", selector: row => toFixed(row?.partnersPreviousMonthCommission), wrap: true },
        { name: 'Total Comission ($)', width: "250px", selector: row => toFixed(row?.previousMonthCommission), wrap: true },
        { name: 'Total Partners', selector: row => toFixed(row?.totalPartners), wrap: true },
    ];

    useEffect(() => {
        getTotalComission();
    }, []);

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };

    // Calculate the data to display based on the current page
    const startIndex = currentPage * itemsPerPage;
    const displayedData = commissionData.slice(startIndex, startIndex + itemsPerPage);
    const pageCount = Math.ceil(commissionData.length / itemsPerPage);

    const showNotification = () => {
        let notifyContainer = document.getElementsByClassName("notification-container")[0];
        if (notifyContainer.classList.contains("d-none")) {
            notifyContainer.classList.add("active");
            notifyContainer.classList.remove("d-none");
        } else {
            notifyContainer.classList.add("d-none");
            notifyContainer.classList.remove("active");
        }
    };

    // *********Export Trade Data In PDF Formats ************* // 
    const exportToPDF = () => {
        const doc = new jsPDF('landscape', 'mm', 'a4');
        doc.text('Comission Credit Report', 100, 10);
        const tableHeaders = ['Serial No.', 'Date/Time', 'Comission ($)'];
        const tableData = commissionData.map((trade, index) => [index + 1, moment(trade?.createdAt).format("MMMM Do YYYY, h:mm:ss a"), trade?.credit_amount]);

        const fixedColumnWidths = [80, 80, 80];
        const columnStyles = {};
        tableHeaders.forEach((_, index) => {
            columnStyles[index] = { cellWidth: fixedColumnWidths[index] };
        });

        doc.autoTable({
            head: [tableHeaders],
            body: tableData,
            theme: 'grid',
            columnStyles: columnStyles,
        });

        doc.save('CV_Trdae_Partner_Comission.pdf');
    };


    return (
        <>
            <div className="wrapper dashboard_page">
                <div className="page-body d-flex align-items-stretch">
                    <Sidebar />
                    <div className="page-content">
                        <div className="bg-dash-dark-2 py-4">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="main_search">
                                        {/* Search input can be added here if needed */}
                                    </div>
                                    <div className="nofti_bar">
                                        <button className="nofti_btn" onClick={showNotification}>
                                            <span className="alert_badge"></span>
                                            <i className="ri-notification-4-fill"></i>
                                        </button>

                                        <div className="notification-container d-none">
                                            <h3>Notifications</h3>
                                            <label className="notification new text-center">No notification</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section>
                            <div className="pg_title">
                                <div className="d-flex align-items-start justify-content-between mb-2 mb-md-3">
                                    <div>
                                        <h2 className="h5 mb-0 mb-3">Comission Report</h2>
                                        <nav aria-label="breadcrumb mb-0">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><a href="/">Dashboard</a></li>
                                                <li className="breadcrumb-item active" aria-current="page">Comission Report</li>
                                            </ol>
                                        </nav>
                                    </div>
                                    <button className="btn btn-light bg-white export_btn" onClick={exportToPDF}>
                                        <i className="ri-download-2-line me-2 ri-xl"></i> Export
                                    </button>
                                </div>
                            </div>
                            <div className="grid-container row g-4">
                                <div className="col-lg-12">
                                    <DataTable
                                        direction="auto"
                                        responsive
                                        subHeaderAlign="right"
                                        subHeaderWrap
                                        striped
                                        highlightOnHover
                                        fixedHeader
                                        columns={columns}
                                        data={displayedData} // Use sliced data here
                                    />

                                    <ReactPaginate
                                        previousLabel={"< Previous"}
                                        nextLabel={"Next >"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageChange}
                                        containerClassName={"customPagination"}
                                        activeClassName={"active"}
                                    />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ComissionReport;
