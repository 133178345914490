
// src/GeoChart/GeoChart.js

import React from "react";
import { Chart } from "react-google-charts";

export function OperationalCountries({ data }) {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", flexDirection: "column" }}>
        <Chart
          chartEvents={[
            {
              eventName: "select",
              callback: ({ chartWrapper }) => {
                const chart = chartWrapper.getChart();
                const selection = chart.getSelection();
                if (selection.length === 0) return;
                const region = data[selection[0].row + 1];
                console.log("Selected: " + region);
              },
            },
          ]}
          chartType="GeoChart"
          width="100%"
          height="100%"
          data={data}
          options={{
            colorAxis: { colors: ["#e7f1ff", "green"] }, // Custom color gradient
            datalessRegionColor: "#f8f8f8",
            defaultColor: "#f9c2c2",
          }}
        />

      </div>

    </>
  );
}

export default OperationalCountries;
