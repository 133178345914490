import React, { useEffect, useState } from "react";
import Sidebar from "../../CoustamComponents/Sidebar";
import AuthService from "../../../Services/AuthService";
import LoaderHelper from "../../../Services/Loading/LoaderHelper";
import moment from "moment";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const WithdrawalList = () => {
    const [withdrawalData, setWithdrawalData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [totalData, setTotalData] = useState(0);

    const pageCount = Math.ceil(totalData / itemsPerPage);

    const skip = (currentPage - 1) * itemsPerPage;

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);
    };

    const getWithdrawals = async () => {
        try {
            LoaderHelper.loaderStatus(true);
            const withdrawalResponse = await AuthService.withdrawalRequestListUser();
            if (withdrawalResponse?.success) {
                const fetchedData = withdrawalResponse?.data;
                setWithdrawalData([...fetchedData]);
                setTotalData(fetchedData.length);
            }
        } finally {
            LoaderHelper.loaderStatus(false);
        }
    };

    const statusShow = (row) => {
        return (
            <div>
                <span className={`text-${row?.status === "PENDING" ? "warning" : row?.status === "APPROVED" ? "success" : "danger"}`}>{row?.status}</span>
            </div>
        )
    }

    const columns = [
        { name: 'Sr no.', width: "80px", selector: (row, index) => skip + index + 1, wrap: true },
        { name: 'Date', sort: true, selector: row => moment(row?.createdAt).format('MMMM Do YYYY'), wrap: true },
        { name: 'Amount', selector: row => row?.amount, wrap: true },
        { name: 'To Address', selector: row => row?.to_address, wrap: true },
        { name: 'Transaction Hash', selector: row => row?.transaction_hash || "---", wrap: true },
        { name: 'Status', selector: statusShow, wrap: true },
    ];

    useEffect(() => {
        getWithdrawals();
    }, []);

    const showNotification = () => {
        let notifyContainer = document.getElementsByClassName("notification-container")[0];
        if (notifyContainer.classList.contains("d-none")) {
            notifyContainer.classList.add("active");
            notifyContainer.classList.remove("d-none");
        } else {
            notifyContainer.classList.add("d-none");
            notifyContainer.classList.remove("active");
        }
    }


    // *********Export Trade Data In PDF Formats ************* // 
    const exportToPDF = () => {
        const doc = new jsPDF('landscape', 'mm', 'a4');
        doc.text('Withdrawal History', 100, 10);

        const tableHeaders = ['Serial No.', 'Date/Time', 'Amount', 'To Address', 'Transaction Hash', 'Status'];
        const tableData = withdrawalData.map((trade, index) => [
            index + 1,
            moment(trade?.createdAt).format("MMMM Do YYYY, h:mm:ss a"),
            trade?.amount,
            trade?.to_address,
            trade?.transaction_hash,
            trade?.status
        ]);
        const fixedColumnWidths = [30, 30, 30, 60, 60, 30];
        const columnStyles = {};
        tableHeaders.forEach((_, index) => {
            columnStyles[index] = { cellWidth: fixedColumnWidths[index] };
        });

        doc.autoTable({
            head: [tableHeaders],
            body: tableData,
            theme: 'grid',
            columnStyles: columnStyles,
        });

        doc.save('CVTrade-Partner-Withdrawal-History.pdf');
    };


    return (
        <>
            <div className="wrapper dashboard_page">
                <div className="page-body d-flex align-items-stretch">
                    <Sidebar />
                    <div className="page-content">
                        <div className="bg-dash-dark-2 py-4">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                <div className="main_search">
                                        {/* Search input can be added here if needed */}
                                    </div>
                                    <div className="nofti_bar">
                                        <button className="nofti_btn" onClick={showNotification}>
                                            <span className="alert_badge"></span>
                                            <i className="ri-notification-4-fill"></i>
                                        </button>
                                        <div className="notification-container d-none">
                                            <h3>Notifications</h3>
                                            <label className="notification new text-center" htmlFor="size_1">No notification</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section>
                            <div className="pg_title">
                                <div className="d-flex align-items-start justify-content-between mb-2 mb-md-3">
                                    <div>
                                        <h2 className="h5 mb-0 mb-3">Withdrawal Request</h2>
                                        <nav aria-label="breadcrumb mb-0">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><a href="/">Dashboard</a></li>
                                                <li className="breadcrumb-item active" aria-current="page">Withdrawal History</li>
                                            </ol>
                                        </nav>
                                    </div>
                                    <button className="btn btn-light bg-white export_btn" onClick={exportToPDF}>
                                        <i className="ri-download-2-line me-2 ri-xl"></i> Export
                                    </button>
                                </div>
                            </div>
                            <div className="grid-container row g-4">
                                <div className="col-lg-12">
                                    {/* DataTable Component */}
                                    <DataTable
                                        direction="auto"
                                        responsive
                                        subHeaderAlign="right"
                                        subHeaderWrap
                                        striped
                                        highlightOnHover
                                        fixedHeader
                                        columns={columns}
                                        data={withdrawalData.slice(skip, skip + itemsPerPage)} // Show paginated data
                                    />

                                    {/* Pagination Component */}
                                    <ReactPaginate
                                        pageCount={pageCount}
                                        onPageChange={handlePageChange}
                                        containerClassName={'customPagination'}
                                        activeClassName={'active'}
                                    />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WithdrawalList;
