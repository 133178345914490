
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function alertErrorMessage(message) {
  if (!toast.isActive('Toasterror')) {
    toast.error((message && typeof (message) === "string") ? message?.toUpperCase() : "Error", {
      toastId: 'Toasterror',
      position: "top-center",
      autoClose: 500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
    });
  }
}

function alertSuccessMessage(message) {
  if (!toast.isActive('Toastsuccess')) {
    toast.success((message && typeof (message) === "string") ? message?.toUpperCase() : "Success", {
      toastId: 'Toastsuccess',
      position: 'top-center',
      autoClose: 500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false
    });
  }
}



function alertWarningMessage(message) {
  if (!toast.isActive('Toastwarning')) {
    toast.warning((message && typeof (message) === "string") ? message?.toUpperCase() : "Error", {
      toastId: 'Toastwarning',
      position: 'top-center',
      autoClose: 500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false
    });
  }
}


export { alertErrorMessage, alertSuccessMessage, alertWarningMessage };