import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useState } from "react";
import { ApiConfig } from "../../../Services/AuthAction/ApiConfig";
import { ProfileContext } from "../../../Services/ProfileProvider";

const Sidebar = () => {


  const emailAddress = sessionStorage.getItem('emailAddress');
  const { setLoginDetails, userDetails } = useContext(ProfileContext);
  const [isToggle, setIsToggle] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    sessionStorage.clear();
    setLoginDetails({})
    navigate("/");
  }


  return (
    <nav id="sidebar" className={`${isToggle === true ? 'active' : 'sidebar-fixed'}`}>
      <div className=" d-flex align-items-center py-2 p-4  ">
        <img src="images/logo.svg" width="190" className="navbar-brand" />
        <button className="navbar-toggler ms-2 d-block d-xl-none" onClick={() => setIsToggle(!isToggle)}><span className="navbar-toggler-icon"></span></button>
      </div>
      {/* < hr /> */}

      <ul className="list-unstyled side_scroll mt-4">
        <li className="menu-heading"><h5 className="overline-title text-primary-alt"> Menu </h5></li>
        <li className='sidebar-item' >
          <Link to="/dashboard" className={`sidebar-link ${(location.pathname === '/dashboard') && 'active'}`}>
            <span className="svg-icon svg-icon-sm svg-icon-heavy">
              <img src="images/dashboard_icon.svg" alt="" className="img-fluid" />
            </span>
            <span>Dashboard </span>
          </Link>
        </li>

        {/* <li className="sidebar-item">
          <a href="#exampledropdownDropdown3" data-bs-toggle="collapse" className={`sidebar-link ${(location.pathname === '/profile' || location.pathname === '/editProfile') && 'active'}`}>
            <span className="svg-icon svg-icon-sm svg-icon-heavy"> <i className="ri-user-line"></i> </span>
            <span>Profile</span>
          </a>
          <ul className={`collapse list-unstyled ${(location.pathname === '/profile' || location.pathname === '/editProfile') && 'show'}`} id="exampledropdownDropdown3" >
            <li> <Link to="/profile" className={`sidebar-link ${(location.pathname === '/profile') && 'active'}`}> My Profile </Link> </li>
            <li> <Link to="/editProfile" className={`sidebar-link ${(location.pathname === '/editProfile') && 'active'}`}> Edit Profile </Link> </li>
          </ul>
        </li> */}



        <li className='sidebar-item' >
          <a href="#exampledComission" data-bs-toggle="collapse" className={`sidebar-link ${(location.pathname === '/UserComissionReport' || location.pathname === '/WithdrawalComission') && 'active'}`}>
            <span className="svg-icon svg-icon-sm svg-icon-heavy">
              <img src="images/commission_report.svg" alt="" className="img-fluid" />
            </span>
            <span>Exchange Profit</span>
          </a>
          <ul className={`collapse list-unstyled ${(location.pathname === '/UserComissionReport' || location.pathname === '/WithdrawalComission') && 'show'}`} id="exampledComission" >
            <li> <Link to="/UserComissionReport" className={`sidebar-link ${(location.pathname === '/UserComissionReport') && 'active'}`}> Trading Commission Report </Link> </li>
            <li> <Link to="/WithdrawalComission" className={`sidebar-link ${(location.pathname === '/WithdrawalComission') && 'active'}`}>Withdrawal Commission Report </Link> </li>
            <li> <Link to="/QuickBuySellCommission" className={`sidebar-link ${(location.pathname === '/QuickBuySellCommission') && 'active'}`}>Quick Buy Sell Commission Report </Link> </li>

          </ul>
        </li>
        {/* 
        <li className='sidebar-item' >
          <Link to="/WithdrawalComission" className={`sidebar-link ${(location.pathname === '/WithdrawalComission') && 'active'}`}>
            <span className="svg-icon svg-icon-sm svg-icon-heavy">
              <i class="ri-file-list-fill"></i>
            </span>
            <span>Withdrawal Commission Report </span>
          </Link>
        </li> */}

        <li className='sidebar-item' >
          <Link to="/comissionReport" className={`sidebar-link ${(location.pathname === '/comissionReport') && 'active'}`}>
            <span className="svg-icon svg-icon-sm svg-icon-heavy">
              <img src="images/commission_credit.svg" alt="" className="img-fluid" />
            </span>
            <span>Comission Credit </span>
          </Link>
        </li>

        <li className='sidebar-item' >
          <Link to="/WithdrawalList" className={`sidebar-link ${(location.pathname === '/WithdrawalList') && 'active'}`}>
            <span className="svg-icon svg-icon-sm svg-icon-heavy">
              <img src="images/withdrawal_history.svg" alt="" className="img-fluid" />
            </span>
            <span>Withdrawal History </span>
          </Link>
        </li>


        {/* <li className='sidebar-item' >
          <Link to="/profile_report" className={`sidebar-link ${(location.pathname === '/profile_report') && 'active'}`}>
            <span className="svg-icon svg-icon-sm svg-icon-heavy">
              <i className="ri-article-line"></i>
            </span>
            <span>Profile's Report </span>
          </Link>
        </li> */}

        <li className='sidebar-item' >
          <Link to="/Certificate" className={`sidebar-link ${(location.pathname === '/Certificate') && 'active'}`}>
            <span className="svg-icon svg-icon-sm svg-icon-heavy">
              <img src="images/certificate.svg" alt="" className="img-fluid" />
            </span>
            <span>Certificate</span>
          </Link>
        </li>
        {/* 
        <li className='sidebar-item' >
          <Link to="/suggestions" className={`sidebar-link ${(location.pathname === '/suggestions') && 'active'}`}>
            <span className="svg-icon svg-icon-sm svg-icon-heavy">
              <i className="ri-blaze-line"></i>
            </span>
            <span>Suggestions</span>
          </Link>
        </li> */}

        {/* <li className='sidebar-item' >
          <Link to="/notifications" className={`sidebar-link ${(location.pathname === '/notifications') && 'active'}`}>
            <span className="svg-icon svg-icon-sm svg-icon-heavy">
              <i className="ri-notification-line"></i>
            </span>
            <span>Notifications</span>
          </Link>
        </li> */}

        <li className='sidebar-item' onClick={handleLogout}>
          <Link to="#" className={`sidebar-link`}>
            <span className="svg-icon svg-icon-sm svg-icon-heavy">
              <img src="images/logout.svg" alt="" className="img-fluid" />
            </span>
            <span>Logout</span>
          </Link>
        </li>

      </ul>
      <div className="sidebar-item px-3 mt-3" >

        <div className="menu-heading pb-0"><h5 className="overline-title text-primary-alt mb-0"> Profile </h5></div>
        <a href="/profile" className=" text-dark sidebar-header d-flex align-items-center p-4 pt-3   ">

          <img src={userDetails?.profilePicture ? `${ApiConfig.baseUrl + userDetails.profilePicture}` : "images/user.png"} onError={(e) => e.target.src = "images/user_thumb.png"} alt="user avatar" />

          <div className="ms-3 title d-flex align-items-center justify-content-between w-100">

            <h1 className="h5 mb-1">
              {userDetails?.userName || '---'}
              <br />
              <small>
                {/* {emailAddress} */}
                Partner
              </small>
            </h1>
            <i class="ri-arrow-right-s-line"></i>

          </div>
        </a>



        {/* <button type="button" className="btn btn-danger w-100" onClick={handleLogout}>
          <span className="svg-icon svg-icon-sm svg-icon-heavy">
          <img src="images/logout.svg" alt="" className="img-fluid" />
          </span>
          <span> Logout </span>
        </button> */}
      </div>
    </nav>
  );
}
export default Sidebar
