import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const ComissionChart = ({ commissions = [ { category: ' ', value:  0 },] }) => {
  const [chartData, setChartData] = useState({
    series: commissions.map(commission => commission.value), // Extract values from commissions
    options: {
      chart: {
        type: 'donut',
      },
      labels: commissions.map(commission => commission.category), // Extract categories from commissions
      fill: {
        colors: ['#00E396', '#FEB019', '#8d50d8',], // Custom colors
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 500,
              height: 200,
            },
            legend: {
              position: 'right',
            },
          },
        },
      ],
    },
  });

  useEffect(() => {
    setChartData({
      series: commissions.map(commission => commission.value), // Update values based on commissions prop
      options: {
        ...chartData.options, // Keep other options unchanged
        labels: commissions.map(commission => commission.category), // Update labels based on commissions prop
      },
    });
  }, [commissions]);

  return (
    <div style={{ height: '100%', width: '155%' }}>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="donut"
        height="100%"
        width="100%"
      />
    </div>
  );
};

export default ComissionChart;
