
import React, { useEffect, useState } from "react";
import Sidebar from "../../CoustamComponents/Sidebar";
import AuthService from "../../../Services/AuthService";
import LoaderHelper from "../../../Services/Loading/LoaderHelper";
import moment from "moment";
import { ProfileContext } from "../../../Services/ProfileProvider";
import { useContext } from "react";
import { alertErrorMessage, alertSuccessMessage } from "../../../Services/CustomAlertMessage";
import { $ } from "react-jquery-plugin";
import UserGrowth from "../../CoustamComponents/UserGraph/UserGrowth";
import PartnerGrowth from "../../CoustamComponents/UserGraph/PartnerGrowth";
import ComissionChart from "../../CoustamComponents/UserGraph/ComissionChart";
import OperationalCountries from "../../CoustamComponents/UserGraph/OperationalCountries";
import { ApiConfig } from "../../../Services/AuthAction/ApiConfig";
import { countries } from "../../../utils/Country";



const Dashboard = () => {
    const [userGrowthData, setUserGrowthData] = useState({});
    const [partnerGrowthData, setPartnerGrowthData] = useState({});
    const [kycUsers, setKycUsers] = useState();
    const [operationalCountries, setOperationalCountries] = useState({});
    const [commissionData, setCommissionData] = useState({});
    const [commissionDataPerDay, setCommissionDataPerDay] = useState({});
    const [previousMonthCom, setPreviousMonthCom] = useState({});
    const [walletBalance, setwalletBalance] = useState([]);
    const [pairPrice, setPairPrice] = useState(0);
    const [partnerList, setPartnerList] = useState([]);
    const [formData, setFormData] = useState({ otp: "", address: "", amount: "" });
    const { userDetails } = useContext(ProfileContext);

    const getTotalComission = async () => {
        try {
            const comissionPerDay = await AuthService.comissionPerDay();
            const monthlyCommission = await AuthService.coinCommission();
            const previousMonthCommission = await AuthService.coinCommissionPreviousMonth();
            if (monthlyCommission?.success) {
                setCommissionData(monthlyCommission?.data);
            }
            if (comissionPerDay?.success) {
                setCommissionDataPerDay(comissionPerDay?.data);
            }
            if (previousMonthCommission?.success) {
                setPreviousMonthCom(previousMonthCommission?.data);
            }
        } finally { LoaderHelper.loaderStatus(false); }
    };

    const getUserfunds = async () => {
        try {
            const result = await AuthService.getUserfunds();
            if (result?.success) {
                setwalletBalance(result?.data[0]);
            }
        } finally { LoaderHelper.loaderStatus(false); }
    };

    const getPartnersList = async () => {
        try {
            const result = await AuthService.getpartnerships();
            if (result?.success) {
                const today = new Date();
                today.setHours(0, 0, 0, 0);

                const filteredPartners = result?.data.filter(partner => {
                    const updatedAt = new Date(partner.updatedAt);
                    return partner.status === 'ACTIVE' &&
                        updatedAt.setHours(0, 0, 0, 0) === today.getTime();
                });
                setPartnerList(filteredPartners);
            }
        } finally {
            LoaderHelper.loaderStatus(false);
        }
    };


    const getPairPrice = async () => {
        try {
            const result = await AuthService.pairPrice();
            if (result?.success) {
                setPairPrice(result?.data?.buy_price || 0);
            }
        } finally { LoaderHelper.loaderStatus(false); }
    };

    const getUserGriwthData = async () => {
        try {
            const result = await AuthService.user_growth_data();
            if (result?.success) {
                setUserGrowthData(result?.data)
            }
        } finally { LoaderHelper.loaderStatus(false); }
    }

    const getPartnersGriwthData = async () => {
        try {
            const result = await AuthService.partners_growth_data();
            if (result?.success) {
                setPartnerGrowthData(result?.data)
            }
        } finally { LoaderHelper.loaderStatus(false); }
    }
        const operation_countries = async () => {
            try {
                const result = await AuthService.operation_countries();
                if (result?.success) {
                    setOperationalCountries(result?.data);
                    const userCounts = result?.data?.slice(1)?.reverse().map(item => item[1]);
                    const maxCount = Math.max(...userCounts); // Find the maximum value for scaling
        
                    // Get chart container
                    const chartContainer = document.getElementById('chart-container');
        
                    // Clear existing bars (if the function is called multiple times)
                    chartContainer.innerHTML = '';
        
                    let rowWrapper = null; // Wrapper for two bars
                    let countInRow = 0;    // Track how many bars are in the current row
        
                    // Dynamically generate the bars
                    result?.data?.slice(1).forEach(([country, count]) => {
        
                        let countryImage = countries?.filter((item) => item?.name === country)[0];
                        const widthPercentage = Math.max((count / maxCount) * 100, 20 + (count / maxCount) * 100);
        
                        // Create bar elements
                        const bar = document.createElement('div');
                        bar.classList.add('bar');
                        bar.style.flex = '1'; // Add flex to make bars responsive
                        bar.style.backgroundColor = '#f7fafc'; // Bar styling
                        bar.style.padding = '11px';
                        bar.style.borderRadius = '12px';
        
                        const label = document.createElement('span');
                        const image = document.createElement('img');
                        image.style.width = '30px';
                        image.src = `https://country-code-au6g.vercel.app/${countryImage?.code}.svg`;
        
                        label.classList.add('label', 'label-small',);
                        label.textContent = country;
                        label.style.fontSize = '12px'; // Adjust font size
                        image.style.marginRight = '30px'; // Adjust margin
                        image.style.marginLeft = '10px'; // Adjust margin
        
                        const progress = document.createElement('div');
                        progress.classList.add('progress');
        
                        const progressBar = document.createElement('div');
                        progressBar.classList.add('progress-bar', 'bg-success', 'label-small');
                        progressBar.style.backgroundColor = 'green'; // Set progress bar color
                        progressBar.style.width = `${widthPercentage}%`;
                        progressBar.textContent = `${count}`; // Display the count inside the bar
        
                        // Add hover effect to show the count
                        label.setAttribute('title', `${count} users`);
                        progressBar.setAttribute('title', `${count} users`);
        
                        // Append elements to the bar
                        progress.appendChild(progressBar);
                        bar.appendChild(label);
                        bar.appendChild(image);
                        bar.appendChild(progress);
        
                        // Add to row wrapper
                        if (countInRow === 0) {
                            rowWrapper = document.createElement('div');
                            rowWrapper.classList.add('rowWrapper');
                            rowWrapper.style.display = 'flex';
                            // rowWrapper.style.justifyContent = 'space-between';
                            rowWrapper.style.gap = '10px';  // Add a 10px gap between countries
                            rowWrapper.style.marginBottom = '15px'; // Add spacing between rows
                            chartContainer.appendChild(rowWrapper);
                        }
        
                        rowWrapper.appendChild(bar);
                        countInRow++;
        
                        // Reset after two countries
                        if (countInRow === 2) {
                            countInRow = 0;
                        }
                    });
        
                    setKycUsers(result?.totalUserCount);
                }
            } finally {
                LoaderHelper.loaderStatus(false);
            }
        };
        



    const withdrawalRequest = async () => {
        if (!userDetails?.email || !formData?.otp || !formData?.address || !formData?.amount || formData?.amount <= 0) return;
        try {
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.withdrawalRequest(userDetails?.email, +formData?.otp, formData?.address, toFixed(+formData?.amount * pairPrice));
            if (result?.success) {
                alertSuccessMessage("Withdrawal request submitted");
                $('#Withdraw_modal').modal('hide');
                setFormData({ otp: "", address: "", amount: "" })
                getUserfunds()
            } else {
                alertErrorMessage(result?.message)
            }
        } finally { LoaderHelper.loaderStatus(false); }
    };

    const handleGetOtp = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getOtp(userDetails?.email, true).then(async (result) => {
            LoaderHelper.loaderStatus(false);
            if (result.success) {
                alertSuccessMessage(`OTP sent to ${userDetails?.email}`);

            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    };

    const handleWithdrawalInput = (e) => {
        const { value, name } = e.target;
        setFormData({ ...formData, [name]: value })
    }

    const toFixed = (data) => parseFloat(data?.toFixed(5))


    const showNotification = () => {
        let notifyContainer = document.getElementsByClassName("notification-container")[0]
        if (notifyContainer.classList.contains("d-none")) {
            notifyContainer.classList.add("active")
            notifyContainer.classList.remove("d-none")
        } else {
            notifyContainer.classList.add("d-none")
            notifyContainer.classList.remove("active")
        }
    };

    const handleAmountFill = (perc) => {
        let amountPerc = perc / 100;
        let balance = (walletBalance?.balance / pairPrice) || 0
        let finalAmount = balance * amountPerc
        setFormData({ ...formData, amount: finalAmount })

    }



    useEffect(() => {
        getTotalComission()
        getUserfunds()
        getPairPrice()
        getPartnersList()
        getUserGriwthData()
        getPartnersGriwthData()
        operation_countries()
    }, []);

    return (
        <>
            <div className="wrapper dashboard_page ">
                <div className=" page-body d-flex align-items-stretch">
                    <Sidebar />
                    <div className="page-content">
                        <div className="bg-dash-dark-2 py-4">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between" >
                                    {/* <h2 className="h5 mb-0">Dashboard</h2> */}
                                    <div className="main_search" >
                                        {/* <input className="form-control" type="search" placeholder="Search.." />
                                        <i class="ri-search-2-line"></i> */}
                                    </div>
                                    <div className="nofti_bar" >
                                        <button className="nofti_btn" onClick={showNotification} >
                                            <span class="alert_badge"></span>
                                            <i class="ri-notification-4-fill"></i>
                                        </button>

                                        <div class="notification-container d-none">
                                            <h3>Notifications </h3>
                                            {/* <label class="notification new" for="size_1"><em>1</em> new <a href="">guest account(s)</a> have been created.</label> */}
                                            <label class="notification new text-center" for="size_1"> No notification</label>

                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                        <section>
                            <div className="grid-container row g-4" >
                                <div className={`col-lg-${userDetails?.created_by === "SELF" ? "12" : "12"} `} >
                                    <div className="card h-100" >
                                        <div className="card-header" >
                                            <h4 class="card-title">Today's Profit</h4>
                                        </div>
                                        <div className="card-body pt-0" >
                                            <div className="row g-3" >
                                                <div className="col-lg-3" >
                                                    <div className="card_c c_bg_1 " >
                                                        <div className="wuuaxe" >
                                                            <i class="ri-line-chart-line"></i>
                                                        </div >
                                                        <div className="comm_txt" >
                                                            <span>Trading Fee</span>
                                                            <h3>
                                                                {Object.keys(commissionData)?.length === 0 ? <div class="spinner-border text-dark text-center" role="status">
                                                                    <span class="visually-hidden">Loading...</span></div> : `$${toFixed(commissionData?.makertakerPerMonth) || 0}`}
                                                                <br />
                                                                <em className="badge bg-success" >Today: ${toFixed(commissionDataPerDay?.makertakerPerDay) || 0}</em>
                                                            </h3>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-lg-3" >
                                                    <div className="card_c c_bg_2 " >
                                                        <div className="wuuaxe" >
                                                            <i class="ri-corner-up-left-line"></i>
                                                        </div>
                                                        <div className="comm_txt" >
                                                            <span>Withdrawal Fee</span>
                                                            <h3>
                                                                {Object.keys(commissionData)?.length === 0 ? <div class="spinner-border text-dark text-center" role="status">
                                                                    <span class="visually-hidden">Loading...</span></div> : `$${toFixed(commissionData?.withdrawalFeesPerMonth) || 0}`}
                                                                <br />
                                                                <em className="badge bg-success" >Today: ${toFixed(commissionDataPerDay?.withdrawalFeesPerDay) || 0}</em>
                                                            </h3>
                                                        </div>

                                                        {/* <div class="MuiCardContent-root">
                                                            <h3 class="typography-h3"></h3>
                                                            <small>Today:</small>
                                                            <p class="typography-h6 ">Withdrawal Commission</p>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3" >
                                                    <div className="card_c c_bg_5  " >
                                                        <div className="wuuaxe" >
                                                            <i class="ri-send-plane-line"></i>
                                                        </div >
                                                        <div className="comm_txt" >
                                                            <span>Quick Buy Sell Fee</span>
                                                            <h3>
                                                                {Object.keys(commissionData)?.length === 0 ? <div class="spinner-border text-dark text-center" role="status">
                                                                    <span class="visually-hidden">Loading...</span></div> : `$${toFixed(commissionData?.quickbuyOverallpermonth) || 0}`}
                                                                <br />
                                                                <em className="badge bg-success" >Today: ${toFixed(commissionDataPerDay?.quickbuyFeesPerDayFee) || 0}</em>
                                                            </h3>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-lg-3" >
                                                    <div className="card_c c_bg_6  " >
                                                        <div className="wuuaxe" >
                                                            <i class="ri-file-list-3-line"></i>
                                                        </div >
                                                        <div className="comm_txt" >
                                                            <span>Listing Fee</span>
                                                            <h3>
                                                                $0
                                                                <br />   <em className="badge bg-success" >Today: 0%</em>
                                                            </h3>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {userDetails?.created_by === "SELF" &&
                                    <div className="col-lg-6" >
                                        <div className="card h-100" >
                                            <div className="card-header d-flex align-items-center justify-content-between" >
                                                <h4 class="card-title">  Staking Details </h4>
                                                {/* <btn className="btn btn-primary btn-sm" > View All </btn> */}
                                            </div>
                                            <div className="card-body pt-0" >
                                                <div className="row g-3" >
                                                    <div className="col-lg-12" >
                                                        <div className="card_c c_card_full c_bg_3 card_cr " >

                                                            <div className="comm_txt" >
                                                                <span>Total Income </span>
                                                                <h3 className="mb-0" >
                                                                    CVT {toFixed(walletBalance?.balance / pairPrice) || 0}
                                                                </h3>
                                                                <small>${toFixed(walletBalance?.balance) || 0}</small>
                                                            </div>
                                                            <a href="#/" className="btn btn-success btn-md  " title="Withdraw" data-bs-toggle="modal" data-bs-target="#Withdraw_modal"> Withdraw </a>



                                                            {/* <div class="MuiCardContent-root">
                                                                <h3 class="typography-h3">${toFixed(walletBalance?.balance) || 0}  </h3>
                                                                <p class="typography-h6"> {toFixed(walletBalance?.balance / pairPrice) || 0} CVT  </p>
                                                                <a href="#/" className="btn btn-success btn-sm mt-4 " title="Withdraw" data-bs-toggle="modal" data-bs-target="#Withdraw_modal"> <i class="ri-luggage-deposit-line"></i> </a>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6" >
                                                        <div className="card_c c_bg_0 card_cr " >
                                                            <div className="comm_txt" >
                                                                <span>Staked   </span>
                                                                <h3 className="mb-0" >
                                                                     ${toFixed(userDetails?.deposited_amount) || 0}
                                                                </h3>
                                                                <p class="typography-h6 "> <small> Staking Date   {moment(userDetails?.createdAt).format("DD-MM-YY")} </small>  </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6" >
                                                        <div className="card_c c_bg_0 card_cr " >
                                                            <div className="comm_txt" >
                                                                <span>Return   </span>
                                                                <h3 className="mb-0" >
                                                                   ${toFixed(userDetails?.deposited_amount + userDetails?.deposited_amount * 0.2 ) || 0}
                                                                </h3>
                                                                <p class="typography-h6 "> <small> Release Every Month  5%  Till 20 Months </small>  </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>}

                                {userDetails?.created_by !== "SELF" &&
                                    <div className="col-lg-6" >
                                        <div className="card h-100" >
                                            <div className="card-header d-flex align-items-center justify-content-between" >
                                                <h4 class="card-title">  Wallet Details </h4>
                                                {/* <btn className="btn btn-primary btn-sm" > View All </btn> */}
                                            </div>
                                            <div className="card-body pt-0" >
                                                <div className="row g-3" >
                                                    <div className="col-lg-12" >
                                                        <div className="card_c c_card_full c_bg_3 card_cr " >

                                                            <div className="comm_txt" >
                                                                <span>Total Income </span>
                                                                <h3 className="mb-0" >
                                                                    CVT {toFixed(walletBalance?.balance / pairPrice) || 0}
                                                                </h3>
                                                                <small>${toFixed(walletBalance?.balance) || 0}</small>
                                                            </div>
                                                            <a href="#/" className="btn btn-success btn-md  " title="Withdraw" data-bs-toggle="modal" data-bs-target="#Withdraw_modal"> Withdraw </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>}

                                <div className="col-lg-6" >
                                    <div className="card h-100" >
                                        <div className="card-header d-flex align-items-center justify-content-between" >
                                            <h4 class="card-title"> Previous Month Commission </h4>
                                        </div>
                                        <div className="card-body pt-0" >
                                            <div className="row g-3" >
                                                <div className="col-lg-12" >
                                                    <div className="card_c c_card_full c_bg_4 card_cr " >

                                                        <div className="comm_txt" >
                                                            <span>Total Commission </span>
                                                            <h3 className="mb-0" >
                                                                $ {toFixed(previousMonthCom?.makertakerPerMonth + previousMonthCom?.quickbuyOverallpermonth + previousMonthCom?.withdrawalFeesPerMonth) || 0}
                                                            </h3>
                                                            <small> {toFixed((previousMonthCom?.makertakerPerMonth + previousMonthCom?.quickbuyOverallpermonth + previousMonthCom?.withdrawalFeesPerMonth) / pairPrice) || 0} CVT</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4" >
                                                    <div className="card_c c_bg_0 card_cr " >
                                                        <div className="comm_txt" >
                                                            <span>Trading Commission:   </span>

                                                            <p class="typography-h6  mt-3">  ${toFixed(previousMonthCom?.makertakerPerMonth) || 0}  </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4" >
                                                    <div className="card_c c_bg_0 card_cr " >
                                                        <div className="comm_txt" >
                                                            <span>Withdrawal Commission:   </span>

                                                            <p class="typography-h6  mt-3">  ${toFixed(previousMonthCom?.withdrawalFeesPerMonth) || 0}  </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4" >
                                                    <div className="card_c c_bg_0 card_cr " >
                                                        <div className="comm_txt" >
                                                            <span>Quick Buy Sell Fee:   </span>

                                                            <p class="typography-h6  mt-3">  ${toFixed(previousMonthCom?.quickbuyOverallpermonth) || 0}  </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-6" >
                                    <div className="card h-100" >
                                        <div className="card-header" >
                                            <h4 class="card-title">  Total Audience </h4>
                                            <h6 class="card-subtitle  ccc_title text-primary">   {userGrowthData.total} <sub>Customers </sub></h6>
                                        </div>
                                        <div className="card-body pt-0 text-center">
                                            {Object.keys(userGrowthData).length > 0 ?
                                                <UserGrowth categories={userGrowthData.categories} data={userGrowthData.data} />
                                                : <div class="spinner-border text-dark text-center" role="status">
                                                    <span class="visually-hidden">Loading...</span></div>}
                                            {/* <img src="images/graph7.jpg" className="img-fluid" /> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6" >
                                    <div className="card h-100" >
                                        <div className="card-header" >
                                            <h4 class="card-title"> Total Commission  </h4>
                                            <h6 class="card-subtitle text-success">${toFixed(commissionData?.withdrawalFeesPerMonth + commissionData?.makertakerPerMonth + commissionData?.quickbuyOverallpermonth)}</h6>
                                        </div>
                                        <div className="card-body pt-0">
                                            {Object.keys(commissionData)?.length > 0 ?
                                                <ComissionChart
                                                    commissions={[
                                                        { category: 'Withdrawal Commission', value: commissionData?.withdrawalFeesPerMonth || 0 },
                                                        { category: 'Trading Commission', value: commissionData?.makertakerPerMonth || 0 },
                                                        { category: 'Quick Buy Sell Commission', value: commissionData?.quickbuyOverallpermonth || 0 },
                                                    ]}
                                                /> : <div class="spinner-border text-dark text-center" role="status">
                                                    <span class="visually-hidden">Loading...</span></div>}
                                        </div>
                                    </div>
                                </div>



                                <div className="col-lg-6" >
                                    <div className="card h-100" >
                                        <div className="card-header" >
                                            <h4 class="card-title"> Partner Growth Rate </h4>
                                            <h6 class="card-subtitle text-success"> Total Partners : {partnerGrowthData?.total} </h6>
                                        </div>
                                        <div className="card-body pt-0">
                                            {
                                                Object.keys(partnerGrowthData)?.length > 0 ? <PartnerGrowth categories={partnerGrowthData.categories} data={partnerGrowthData.data} /> : <div class="spinner-border text-dark text-center" role="status">
                                                    <span class="visually-hidden">Loading...</span></div>
                                            }


                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6" >
                                    <div className="card h-100" >
                                        <div className="card-header" >
                                            <h4 class="card-title">  Operational Country </h4>
                                            <h6 class="card-subtitle text-success"> KYC Submitted Users : {kycUsers} </h6>
                                        </div>

                                        {console.log("🚀 ~ {Object.keys ~ operationalCountries:", operationalCountries)}
                                        <div className="card-body pt-0">
                                            {Object.keys(operationalCountries)?.length > 0 ? <OperationalCountries data={operationalCountries} /> : <div class="spinner-border text-dark text-center" role="status">
                                                <span class="visually-hidden">Loading...</span></div>
                                            }

                                            {/* <img src="images/graph4.png" className="img-fluid" /> */}
                                        </div>
                                        <div class="chart-container" id="chart-container"></div>
                                    </div>
                                </div>

                                <div className="col-lg-6" >
                                    <div className="card h-100" >
                                        <div className="card-header d-flex align-items-center justify-content-between" >
                                            <h4 class="card-title"> New Partners</h4>
                                        </div>
                                        <div className="card-body pt-0" >
                                            <div className="row g-3 scrollable-partner-list " style={{ maxHeight: '300px', overflowY: 'auto' }} >
                                                {partnerList?.length > 0 ? partnerList?.map((data) => {
                                                    return (
                                                        <>
                                                            <div className="col-12" >
                                                                <div className="card_c c_bg_0 card_cr " >
                                                                    <div className="comm_txt" >
                                                                        <div className="d-flex my-2">
                                                                            <img src={data?.profilePicture ? ApiConfig?.baseUrl + data?.profilePicture : "/images/user_dummy.png"} alt="partner" width="60px" height="60px" className="partner_img" />
                                                                            <div className="d-flex mx-2 partner_data">
                                                                                <storng>{data?.userName}</storng>
                                                                                <small>Partner ID : {data?.PartnershipId}</small>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>

                                                    )
                                                }) : <div className="col-12" >
                                                    <div className="card_c c_bg_0 card_cr " >
                                                        <div className="comm_txt" >
                                                            <div className="d-flex flex-column my-2 justify-content-center align-items-center">
                                                                <img src="/images/no-data.svg" alt="partner" width="60px" height="60px" className="partner_img" />
                                                                <small>No data</small>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>}

                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>
                        </section>
                    </div>
                </div>
            </div>

            {/* Withdraw_modal modal */}
            <div className="modal fade" id="Withdraw_modal" tabIndex="-1" aria-labelledby="Withdraw_modalLaebl" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header flex-column px-8 no-border p-0">
                            <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"> <i className="ri-close-fill"></i></button>
                        </div>
                        <div className="modal-body px-8 py-3">
                            <div className="btn-group btn_radio_group w-100 px-0 pt-0 mb-0">
                                <button type="button" className='btn btn-outline-primary active'>
                                    BEP20
                                </button>
                            </div>

                            <div className="form-group mb-4">
                                <input className="form-control" type="text" name="address" placeholder="Wallet Address" value={formData?.address}
                                    onChange={handleWithdrawalInput} />
                            </div>

                            <div className="col-lg-12 col-md-12 col-12 mb-4">
                                <input className="form-control" type="number" onWheelCapture={e => e.target.blur()} name="amount" placeholder="Amount (CVT)" value={formData?.amount} onChange={handleWithdrawalInput} />

                                <div className="d-flex mt-2 gap-1 justify-content-end">
                                    <button type="button" className="btn btn-xs custom-btn  custom-btn2" onClick={() => handleAmountFill(25)} >
                                        <span> 25%</span>
                                    </button>
                                    <button type="button" className="btn btn-xs custom-btn  custom-btn2" onClick={() => handleAmountFill(50)} >
                                        <span> 50% </span>
                                    </button>
                                    <button type="button" className="btn btn-xs custom-btn  custom-btn2" onClick={() => handleAmountFill(70)} >
                                        <span> 75% </span>
                                    </button>
                                    <button type="button" className="btn btn-xs custom-btn  custom-btn2" onClick={() => handleAmountFill(100)} >
                                        <span>    100% </span>
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12 col-12 mb-4">
                                <div className=" field-otp-box" >
                                    <input type="number" name="otp" id="form-otp" className="form-control" placeholder="Enter Verification Code" onWheelCapture={e => e.target.blur()} value={formData?.otp} onChange={handleWithdrawalInput} />
                                    <button type="button" className="btn btn-xs  custom-btn" onClick={handleGetOtp}>
                                        <span> Get OTP </span>
                                    </button>
                                </div>
                            </div>

                            <div className="form-group mb-4">
                                <button type="button" className="btn btn-gradient btn-small w-100 justify-content-center" disabled={!userDetails?.email || !formData?.otp || !formData?.address || !formData?.amount || formData?.amount <= 0} onClick={withdrawalRequest}>
                                    <span>Withdraw</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard
