import React, { useContext, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { alertErrorMessage, alertSuccessMessage } from "../../../Services/CustomAlertMessage";
import AuthService from "../../../Services/AuthService";
import { ProfileContext } from "../../../Services/ProfileProvider";
import LoaderHelper from "../../../Services/Loading/LoaderHelper";

const ForgotPassword = () => {

    const [formData, setFormData] = useState({ emailId: "", password: "", otp: "" });
    const [showPass, setshowPass] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { setLoginDetails } = useContext(ProfileContext);

    const handleInput = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleLoginSubmit = async (event) => {
        event.preventDefault();
        const { emailId, password } = formData;
        if (!emailId) {
            alertErrorMessage('Please Enter Email Address');
            return;
        } if (!password) {
            alertErrorMessage('Please Enter Password');
            return;
        }
        setIsLoading(true);
        try {
            const result = await AuthService.login(formData);
            if (result?.success) {
                setIsLoading(false);
                alertSuccessMessage(result?.message);
                sessionStorage.setItem("token", result.token);
                sessionStorage.setItem("emailAddress", result.data.email);
                setLoginDetails(result);
                navigate("/dashboard");
            } else {
                setIsLoading(false);
                alertErrorMessage(result?.message);
            }
        } catch (error) {
            setIsLoading(false);
            alertErrorMessage(error);
        }
    };

    const handleGetOtp = async () => {
        if (!formData?.emailId) {
            alertErrorMessage("Please enter email")
            return
        }
        LoaderHelper.loaderStatus(true);
        await AuthService.getOtp(formData?.emailId, true).then(async (result) => {
            LoaderHelper.loaderStatus(false);
            if (result.success) {
                alertSuccessMessage(`OTP sent to ${formData?.emailId}`);

            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    };

    const handleForgotPassword = async () => {
        if (!formData?.emailId) {
            alertErrorMessage("Please enter email")
            return
        }
        if (!formData?.otp) {
            alertErrorMessage("Please enter otp")
            return
        }
        if (!formData?.password) {
            alertErrorMessage("Please enter password")
            return
        }
        LoaderHelper.loaderStatus(true);
        await AuthService.forgot_password(formData?.emailId, formData?.otp, formData?.password).then(async (result) => {
            LoaderHelper.loaderStatus(false);
            if (result.success) {
                alertSuccessMessage("Password updated successfully")
                setFormData({ emailId: "", password: "", otp: "" })
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    };

    return (
        <div>
            <section className="section-padding login_sec">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-12 ">
                            <div className="login-card" >
                                <form className="custom-form  form-border " onSubmit={handleLoginSubmit}>
                                    <div className=" mb-5 d-flex flex-column justify-content-center align-items-center" >

                                        <div className=" mb-2  mb-md-4 d-inline">
                                            <img src="images/logo.svg" className="navbar-brand img-fluid" width="300" />
                                        </div>
                                        <h3 className="mb-2 d-inline" >
                                            <strong className="text-dark" >Forgot Password</strong>
                                        </h3>
                                    </div>
                                    <div className=" cfield_group login_group">
                                        <label className="mb-2"> Email Address* </label>
                                        <div className=" field-otp-box">
                                            <div className="cfield mb-3">
                                                <input type="email" className="form-control" name="emailId" value={formData.emailId} onChange={handleInput} required />
                                                <button type="button" className="btn btn-sm btn-gradient" onClick={handleGetOtp}>
                                                    <span>GET OTP</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" cfield_group login_group">
                                        <label className="mb-2"> OTP*</label>
                                        <div className="cfield mb-3">
                                            <input type="email" className="form-control" name="otp" value={formData.otp} onChange={handleInput} required />
                                        </div>
                                    </div>
                                    <div className=" cfield_group login_group">
                                        <label className="mb-2"> Password* </label>
                                        <div className="cfield mb-3 ">
                                            <div className=" field-otp-box" >
                                                <input type={showPass ? 'text' : "password"} name="password" className="form-control" value={formData.password} onChange={handleInput} required />
                                                <button type="button" className="btn btn-icon btn_view_pass btn-white" onClick={() => { setshowPass(!showPass) }}>
                                                    <i className={`ri-eye${showPass ? "-line" : "-close-line"}`} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="mb-4 text-end" >
                                        <a className="text-dark" > Forgot Password? </a>
                                    </div> */}
                                    <button type="button" className="btn custom-btn btn-gradient btn-border btn-xl w-100 mt-3" onClick={handleForgotPassword}>
                                        {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            : <span>Submit</span>}
                                    </button>

                                    <div className="cfield_group  sss_g login_group text-center mt-3 ">
                                        Back to  <Link to="/" className="text-decoration-underline ms-1 text-dark"> <b>Sign In</b></Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/*                         
                        <div className="col-lg-6 col-12 ">
                            <div className="login_img_card" >
                                <img src="images/login_img.png" alt="" className="img-fluid" />
                            </div>
                        </div> */}
                    </div>

                </div>
            </section>

        </div>


    )
}

export default ForgotPassword