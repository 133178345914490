

import React, { useEffect, useState } from "react";
import Sidebar from "../../CoustamComponents/Sidebar";
import AuthService from "../../../Services/AuthService";
import LoaderHelper from "../../../Services/Loading/LoaderHelper";
import moment from "moment";
import ReactPaginate from 'react-paginate';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import DataTable from "react-data-table-component";
import { ApiConfig } from "../../../Services/AuthAction/ApiConfig";

const UserComissionReport = () => {
    const [commissionData, setCommissionData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100);
    const [totalData, setTotalData] = useState()


    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);
    };

    const pageCount = totalData / itemsPerPage
    const skip = (currentPage - 1) * itemsPerPage;

    const getTotalComission = async (skip, limit) => {
        try {
            LoaderHelper.loaderStatus(true);
            const comissionPerDay = await AuthService.tradingCommission(skip, limit);
            if (comissionPerDay?.success) {
                let filteredItem = comissionPerDay?.data?.filter((item) => item?.fee_type !== "Price_Difference")
                setCommissionData(filteredItem);
                setTotalData(comissionPerDay?.totalCount)
            }

        } finally { LoaderHelper.loaderStatus(false); }
    };

    const assetFormater = (row) => {
        return (
            <><img width="30px" src={`${ApiConfig?.baseUrl}/${row?.coin_image}`} className="mx-2" />{row.short_name} </>
        )
    }

    const sideFormater = (row) => {
        return (
            <>
                {row?.order_details?.side === "SELL" ? <span className="badge_side badge-danger" >{row?.order_details?.side } </span> : <span className="badge_side badge-success" >{row?.order_details?.side || "BUY"} </span>}

            </>
        )
    }


    const columns = [
        { name: "Sr No.", wrap: true, selector: (row, index) => skip + 1 + index, },
        { name: "Date", selector: row => moment(row?.createdAt).format("MMM Do YYYY hh:mm A"), wrap: true },
        { name: "Asset", width: "150px", wrap: true, selector: assetFormater, },
        { name: "Fee", width: "120px", wrap: true, selector: row => row.fee, },
        { name: "Fee Type", width: "120px", wrap: true, selector: row => row.fee_type, },
        { name: "From User", width: "120px", wrap: true, selector: row => row.from_user, },
        { name: "From Order", width: "120px", wrap: true, selector: row => row.fromOrder, },
        { name: "Percentage", width: "120px", wrap: true, selector: row => row.percentage, },
        { name: "Disbursed Amount", width: "150px", wrap: true, selector: row => row.amount, },
        { name: "Side", width: "150px", wrap: true, selector: sideFormater },
        { name: "Price", width: "150px", wrap: true, selector: row => row?.order_details?.price, },
        { name: "Quantity", width: "150px", wrap: true, selector: row => row?.order_details?.filled, },
    ];



    useEffect(() => {
        getTotalComission(skip, 100)
    }, [currentPage, skip]);


    const showNotification = () => {
        let notifyContainer = document.getElementsByClassName("notification-container")[0]
        if (notifyContainer.classList.contains("d-none")) {
            notifyContainer.classList.add("active")
            notifyContainer.classList.remove("d-none")
        } else {
            notifyContainer.classList.add("d-none")
            notifyContainer.classList.remove("active")
        }
    }

    
    // *********Export Trade Data In PDF Formats ************* // 
    const exportToPDF = () => {
        const doc = new jsPDF('landscape', 'mm', 'a4');
        doc.text('Trading Commission Report', 100, 10);

        const tableHeaders = ['Serial No.', 'Date/Time', 'Asset', 'Fee', 'Fee Type', 'From User', 'From Order', 'Percentage', 'Disbursed Amount', 'Side', 'Price', 'Quantity'];
        const tableData = commissionData.map((trade, index) => [
            index + 1,
            moment(trade?.createdAt).format("MMMM Do YYYY, h:mm:ss a"),
            trade?.short_name,
            trade?.fee,
            trade?.fee_type,
            trade?.from_user,
            trade?.fromOrder,
            trade?.percentage,
            trade?.amount,
            trade?.order_details?.side || "BUY" ,
            trade?.order_details?.price,
            trade?.order_details?.filled,
        ]);
        const fixedColumnWidths = [20, 20, 20, 20,20, 20,25, 35,40, 20,20, 20,];
        const columnStyles = {};
        tableHeaders.forEach((_, index) => {
            columnStyles[index] = { cellWidth: fixedColumnWidths[index] };
        });

        doc.autoTable({
            head: [tableHeaders],
            body: tableData,
            theme: 'grid',
            columnStyles: columnStyles,
        });

        doc.save('CVTrade-Partner-Trading-Commission.pdf');
    };

    return (
        <>
            <div className="wrapper dashboard_page ">
                <div className=" page-body d-flex align-items-stretch">
                    <Sidebar />
                    <div className="page-content">
                        <div className="bg-dash-dark-2 py-4">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between" >
                                    {/* <h2 className="h5 mb-0">Dashboard</h2> */}
                                    <div className="main_search" >
                                        {/* <input className="form-control" type="search" placeholder="Search.." />
                                        <i class="ri-search-2-line"></i> */}
                                    </div>
                                    <div className="nofti_bar" >
                                        <button className="nofti_btn" onClick={showNotification}>
                                            <span class="alert_badge"></span>
                                            <i class="ri-notification-4-fill"></i>
                                        </button>
                                        <div class="notification-container d-none">
                                            <h3>Notifications </h3>
                                            {/* <label class="notification new" for="size_1"><em>1</em> new <a href="">guest account(s)</a> have been created.</label> */}
                                            <label class="notification new text-center" for="size_1"> No notification</label>

                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                        <section>
                            <div className="pg_title  " >
                                <div className="d-flex align-items-start justify-content-between mb-2 mb-md-3" >
                                    <div>
                                        <h2 className="h5 mb-0 mb-3"> Trading Commission Report</h2>
                                        <nav aria-label="breadcrumb mb-0">
                                            <ol class="breadcrumb">
                                                <li class="breadcrumb-item"><a href="/">Dashboard</a></li>
                                                <li class="breadcrumb-item active" aria-current="page">Trading Commission Report </li>
                                            </ol>
                                        </nav>
                                    </div>
                                    <button className="btn btn-light bg-white export_btn" onClick={exportToPDF}> <i class="ri-download-2-line me-2 ri-xl"></i> Export </button>
                                </div>
                            </div>
                            <div className="grid-container table_card" >
                                {/* <div className="col-lg-12 " > */}


                                <DataTable
                                    direction="auto"
                                    responsive
                                    subHeaderAlign="right"
                                    subHeaderWrap
                                    striped
                                    highlightOnHover
                                    fixedHeader
                                    columns={columns}
                                    data={commissionData}

                                />

                                {/* </div> */}
                                {totalData > 5 ? <ReactPaginate
                                    pageCount={pageCount}
                                    onPageChange={handlePageChange}
                                    containerClassName={'customPagination'}
                                    activeClassName={'active'}
                                /> : ""}
                            </div>
                        </section>
                    </div>
                </div>
            </div>


        </>
    );
}

export default UserComissionReport
